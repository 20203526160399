import cx from 'classnames'
import Loading from 'components/loading'
import { ROUTES } from 'constants/routes'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'

const LandingPage = (): JSX.Element => {
  const { query, push } = useRouter()
  const { isSidebarOpen } = useSelector((state: RootState) => state.app)

  const lastVisited = query.lastVisited

  useEffect(() => {
    // Redirect based on the parameters or query
    const destination =
      lastVisited?.toString() && lastVisited?.toString() !== 'undefined'
        ? decodeURIComponent(lastVisited?.toString())
        : ROUTES.HOME

    push(destination)
  }, [lastVisited, push])

  return (
    <div
      className={cx(
        'bg-white h-[calc(100vh-11.25rem)] pb-2 pr-6 shadow-appLayoutShadow pl-6 pt-6 rounded-2xl',
        'overflow-y-scroll transition-all duration-300 ease-in-out',
        {
          'pr-6': isSidebarOpen,
          'p-[calc(100%-18rem)]': !isSidebarOpen,
        }
      )}>
      <div className="flex flex-col h-full items-center justify-center">
        <Loading className="flex flex-col items-center justify-center" />
        <span className="w-[27.25vw] text-body text-center mt-4">Routing you to the fastest server ...</span>
      </div>
    </div>
  )
}

export default LandingPage
